import React from 'react';
import PocketBase from 'pocketbase';
import VoterList from './components/VoterList';
import './styles.css';

const pb = new PocketBase('https://db.conbackend.com');

// Authenticate with PocketBase (you should use environment variables for credentials)
pb.admins.authWithPassword('contact@brewsocial.club', 'PatchWork22!!');

function App() {
  return (
    <div className="App">
      <h1>Record List</h1>
      <VoterList pb={pb} />
    </div>
  );
}

export default App;