import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import VoterTable from './VoterTable';

interface Voter {
  id: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  residence_address: string;
  age: number;
  district: string;
}

interface VoterListProps {
  pb: PocketBase;
}

const VoterList: React.FC<VoterListProps> = ({ pb }) => {
  const [voters, setVoters] = useState<Voter[]>([]);
  const [sortBy, setSortBy] = useState<'last_name' | 'district'>('last_name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [districts, setDistricts] = useState<string[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState<string>('');

  useEffect(() => {
    fetchVoters();
  }, []);

  const fetchVoters = async () => {
    try {
      const records = await pb.collection('political_voters').getFullList<Voter>();
      setVoters(records);
      
      // Extract unique districts
      const uniqueDistricts = Array.from(new Set(records.map(voter => voter.district)));
      setDistricts(uniqueDistricts);
    } catch (error) {
      console.error('Error fetching voters:', error);
    }
  };

  const handleSort = (column: 'last_name' | 'district') => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const handleDistrictChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDistrict(event.target.value);
  };

  const filteredVoters = selectedDistrict
    ? voters.filter(voter => voter.district === selectedDistrict)
    : voters;

  const sortedVoters = [...filteredVoters].sort((a, b) => {
    const compareResult = a[sortBy].localeCompare(b[sortBy]);
    return sortOrder === 'asc' ? compareResult : -compareResult;
  });

  return (
    <div className="voter-list">
      <div className="group-by">
        <label htmlFor="district-select">Group By District: </label>
        <select
          id="district-select"
          value={selectedDistrict}
          onChange={handleDistrictChange}
        >
          <option value="">All Districts</option>
          {districts.map(district => (
            <option key={district} value={district}>{district}</option>
          ))}
        </select>
      </div>
      <div className="record-count">
        Displaying {sortedVoters.length} record{sortedVoters.length !== 1 ? 's' : ''}
      </div>
      <VoterTable 
        voters={sortedVoters} 
        sortBy={sortBy} 
        sortOrder={sortOrder} 
        onSort={handleSort} 
      />
    </div>
  );
};

export default VoterList;