import React from 'react';
import './VoterTable.css';

interface Voter {
  id: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  residence_address: string;
  age: number;
  district: string;
}

interface VoterTableProps {
  voters: Voter[];
  sortBy: 'last_name' | 'district';
  sortOrder: 'asc' | 'desc';
  onSort: (column: 'last_name' | 'district') => void;
}

const VoterTable: React.FC<VoterTableProps> = ({ voters, sortBy, sortOrder, onSort }) => {
  return (
    <div className="table-container">
      <table className="voter-table">
        <thead>
          <tr>
            <th>Name</th>
            <th className="sortable" onClick={() => onSort('last_name')}>
              Last Name
              <span className="sort-icon">{sortBy === 'last_name' ? (sortOrder === 'asc' ? '▲' : '▼') : '⇅'}</span>
            </th>
            <th>Contact</th>
            <th className="hide-mobile">Address</th>
            <th className="hide-mobile">Age</th>
            <th className="sortable" onClick={() => onSort('district')}>
              District
              <span className="sort-icon">{sortBy === 'district' ? (sortOrder === 'asc' ? '▲' : '▼') : '⇅'}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {voters.map((voter) => (
            <tr key={voter.id}>
              <td data-label="Name">{voter.first_name} {voter.last_name}</td>
              <td data-label="Last Name">{voter.last_name}</td>
              <td data-label="Contact">{voter.phone_number}</td>
              <td data-label="Address" className="hide-mobile">{voter.residence_address}</td>
              <td data-label="Age" className="hide-mobile">{voter.age}</td>
              <td data-label="District">{voter.district}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VoterTable;